<template>
  <div class="bg-primary rounded-lg z-10">
    <div class="flex flex-col lg:flex-row divide-y-1 lg:divide-x-1 lg:divide-y-0 pb-7 sm:py-8 px-7 lg:px-8">
      <div class="w-full text-center lg:text-right lg:pr-8 pb-7 lg:pb-0">
        <h2 class="text-xl sm:text-4xl font-bold tracking-tight text-white">For employers</h2>
        <p class="mt-6 sm:text-lg leading-8 text-indigo-200">
          At {{ $config.public.appName }}, we believe that the right people can transform your business. Our platform is dedicated to
          connecting passionate professionals with leading employers in the hospitality industry. Whether you're looking for a position in
          Accommodation & Lodging, Food & Beverage, or Travel & Tourism, we have the tools and network to help you succeed.
        </p>
        <div class="flex items-center justify-center lg:justify-end mt-10">
          <OutlineButton @click="navigateTo(ROUTES.employers)" class="text-primary">Get started</OutlineButton>
        </div>
      </div>
      <div class="w-full text-center lg:text-left lg:pl-8">
        <h2 class="text-xl sm:text-4xl font-bold tracking-tight text-white">Find a job</h2>
        <p class="mt-6 sm:text-lg leading-8 text-indigo-200">
          At {{ $config.public.appName }}, we believe that the right people can transform any business. Our platform is dedicated to
          connecting passionate professionals with leading employers in the hospitality industry. Whether you're for a position in
          Accommodation & Lodging, Food & Beverage, or Travel & Tourism, we have the tools and network to help you succeed.
        </p>
        <div class="flex items-center justify-center lg:justify-start mt-10">
          <OutlineButton @click="navigateTo(ROUTES.candidates)" class="text-primary">Get started</OutlineButton>
        </div>
      </div>
    </div>
  </div>
</template>
